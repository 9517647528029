import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, Zoom } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}));

const DownloadComponent = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSampleDownload = async () => {
        try {
            const response = await fetch('/assets/sample_bulk_query.csv');

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sample_bulk_query.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('Error downloading file. Please try again.');
        } finally {
            handleClose(); // Close the dialog after download starts
        }
    };

    return (
        <>
            <LightTooltip title="Click here to download the sample CSV and fill up with valid AMD 2D" placement="top" TransitionComponent={Zoom}>
                <IconButton
                    onClick={handleOpen}
                    sx={{
                        color: 'white',
                        '&:hover': {
                            bgcolor: 'rgba(240, 240, 240, 0.1)',
                            '& .MuiSvgIcon-root': {
                                color: '#46d1ff',
                            },
                        },
                    }}
                >
                    <InfoIcon sx={{ fontSize: '1.5rem' }} />
                </IconButton>
            </LightTooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle
                    sx={{ 
                        backgroundColor: 'gray', 
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1.25rem',
                        position: 'relative'
                    }}
                >
                    <Typography variant="h6">Download Sample Excel File</Typography>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'white',
                            '&:hover': {
                                bgcolor: 'rgba(255, 255, 255, 0.3)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{ 
                        backgroundColor: 'gray', 
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                    }}
                >
                    <Typography variant="body2">
                        Click "OK" to start downloading the sample Excel file.
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{ 
                        backgroundColor: 'gray',
                    }}
                >
                    <Button
                        onClick={handleSampleDownload}
                        variant="contained"
                        sx={{
                            fontWeight: 'bold',
                            backgroundColor: 'white',
                            color: 'black',
                            borderColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                borderColor: 'transparent',
                            },
                        }}
                    >
                        OK
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{
                            fontWeight: 'bold',
                            backgroundColor: 'white',
                            color: 'black',
                            borderColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                borderColor: 'transparent',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DownloadComponent;
