import React, { useState, useEffect } from 'react';
import { AppBar, Divider, Typography, Box, Stack, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useOktaAuth } from '@okta/okta-react';

const Footer = ({ title }) => {
    const socialUrls = {
        facebook: 'https://www.facebook.com/AMD/',
        twitter: 'https://x.com/AMD',
        linkedin: 'https://www.linkedin.com/company/amd',
        instagram: 'https://www.instagram.com/AMD/',
        youtube: 'https://www.youtube.com/user/AMD',
        email: 'https://www.amd.com/en/preferences.html'
    };

    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);


    useEffect(() => {
        //console.log(authState)
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            setUserInfo(authState.idToken.claims);
            //console.log(userInfo.email)
            // You can also get user information from the `/userinfo` endpoint
            /*oktaAuth.getUser().then((info) => {
              setUserInfo(info);
            });*/
        }
    }, [authState, oktaAuth]);

    return (
        <AppBar position="static" sx={{ backgroundColor: 'black' }}>
            {userInfo ? (
                <Stack 
                    direction='column'
                    justifyContent='center'
                >
                <Divider orientation="horizontal" variant="middle" color="grey" flexItem sx={{ height: 0.1 }} />
                <Stack
                    direction={{ xs: 'column',sm: 'column', md: 'row' }}
                    spacing={2}
                    useFlexGap
                    flexWrap="wrap"
                    justifyContent={{ xs: 'center', sm: 'center', md: 'space-between' }}
                    alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
                    sx={{
                        paddingLeft: { sm: '5%', md: '15%' },
                        paddingRight: { sm: '5%', md: '15%' },
                        paddingTop: '1rem'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: {  xs: 'column', sm: 'column', md: 'row' },
                        textAlign: {  xs: 'center', sm: 'center', md: 'left' }
                    }}>
                        <img src="/assets/amd_logo.png" alt="AMD Logo" style={{ height: 20 }} />
                        <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{
                            height: { xs: 0, sm: 0, md: 30 },
                            width: {  md: 'auto' },
                            marginBottom: { sm: '10px', md: '0' },
                            marginRight: { md: '10px' },
                            marginLeft: { md: '10px' }
                        }} />
                        <Typography variant="h3" color="white" sx={{fontSize: { sm: '1.2rem', md: '1.2rem', lg: '1.2rem', xl: "1.5rem" }}}>
                            {title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' }, flexWrap: 'wrap' }}>
                        <IconButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={socialUrls.twitter}
                            sx={{ color: 'white', '&:hover': { bgcolor: '#3b5998', color: 'white' } }}
                        >
                            <XIcon sx={{ fontSize: '2rem' }} />
                        </IconButton>

                        <IconButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={socialUrls.linkedin}
                            sx={{ color: 'white', '&:hover': { bgcolor: '#3b5998', color: 'white' } }}
                        >
                            <LinkedInIcon sx={{ fontSize: '2rem' }} />
                        </IconButton>

                        <IconButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={socialUrls.youtube}
                            sx={{ color: 'white', '&:hover': { bgcolor: '#3b5998', color: 'white' } }}
                        >
                            <YouTubeIcon sx={{ fontSize: '2rem' }} />
                        </IconButton>

                        <IconButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={socialUrls.email}
                            sx={{ color: 'white', '&:hover': { bgcolor: '#3b5998', color: 'white' } }}
                        >
                            <EmailIcon sx={{ fontSize: '2rem' }} />
                        </IconButton>

                        <IconButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={socialUrls.facebook}
                            sx={{ color: 'white', '&:hover': { bgcolor: '#3b5998', color: 'white' } }}
                        >
                            <FacebookIcon sx={{ fontSize: '2rem' }} />
                        </IconButton>

                        <IconButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={socialUrls.instagram}
                            sx={{ color: 'white', '&:hover': { bgcolor: '#3b5998', color: 'white' } }}
                        >
                            <InstagramIcon sx={{ fontSize: '2rem' }} />
                        </IconButton>
                    </Box>
                </Stack>

            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                justifyContent='center'
                alignItems='center'
            >
         <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                    textAlign: { xs: 'column', sm: 'center', md: 'left' }
                }}>
                    <Typography
                        variant="h6"
                        color="white"
                        component="a"
                        href="https://www.amd.com/en/legal/copyright.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                    >
                        Terms And Conditions
                    </Typography>
                    
                    <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height: {xs: 0, sm: 0, md: 20}, marginRight: '5px', marginLeft: '5px' }} />

                    <Typography
                        variant="h6"
                        color="white"
                        component="a"
                        href="https://www.amd.com/en/legal/privacy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                    >
                        Privacy
                    </Typography>

                    <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height:{xs: 0, sm: 0, md: 20}, marginRight: '5px', marginLeft: '5px' }} />

                    <Typography
                        variant="h6"
                        color="white"
                        component="a"
                        href="https://www.amd.com/en/legal/trademarks.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                    >
                        Trademarks
                    </Typography>

                    <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height: {xs: 0, sm: 0, md: 20}, marginRight: '5px', marginLeft: '5px' }} />

                    <Typography
                        variant="h6"
                        color="white"
                        component="a"
                        href="https://www.amd.com/content/dam/amd/en/documents/corporate/cr/supply-chain-transparency.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                    >
                        Supply Chain Transparency
                    </Typography>

                    <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height: {xs: 0, sm: 0, md: 20}, marginRight: '5px', marginLeft: '5px' }} />

                    <Typography
                        variant="h6"
                        color="white"
                        component="a"
                        href="https://www.amd.com/en/legal/competition.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                    >
                        Fair & Open Competition
                    </Typography>

                    <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height: {xs: 0, sm: 0, md: 20}, marginRight: '5px', marginLeft: '5px' }} />

                    <Typography
                        variant="h6"
                        color="white"
                        component="a"
                        href="https://www.amd.com/content/dam/amd/en/documents/corporate/amd-uk-tax-strategy.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                    >
                        UK Tax Strategy
                    </Typography>

                    <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height: {xs: 0, sm: 0, md: 20}, marginRight: '5px', marginLeft: '5px' }} />

                    <Typography
                        variant="h6"
                        color="white"
                        component="a"
                        href="https://www.amd.com/en/legal/cookies.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                    >
                        Cookies Policy
                    </Typography>
                    </Box>
            </Stack>

            <Stack
                direction='row'
                justifyContent='center'
            >
                <Typography
                    variant="h6"
                    color="white"
                    sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                >
                    2024 Advanced Micro Devices, Inc.
                </Typography>

            </Stack>



                </Stack>
            ): null}
        </AppBar>
    )
}

export default Footer