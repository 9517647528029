import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';


const downloadJSON = (jsonData, filename = 'searchResult.json') => {
    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(jsonData, null, 2))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = filename;
    link.click();
};


const JSONViewer = ({ searchResult, searchStatus }) => {
    return (
        <Box
            sx={{
                padding: '20px',
                borderRadius: '8px',
                border: '3px solid #fff',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#000',
                color: '#fff',
                overflowY: 'auto',
                minHeight: '100px',
                maxHeight: '800px',
                margin: '5%'
            }}
        >
            <Typography
                variant="h2"
                gutterBottom
                sx={{
                    color: '#ffaa00',
                    fontWeight: 'bold',
                    marginLeft: '3%',
                    marginTop: '2%'
                }}
            >
                File Verification Result
            </Typography>

            {searchResult && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginRight: '5%' }}>
                    <Button
                        variant="contained"
                        onClick={() => downloadJSON(searchResult)}
                        sx={{
                            fontSize: '1rem',
                            backgroundColor: '#00aaff',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#008ecc',
                            },
                        }}
                    >
                        Download JSON
                    </Button>
                </Box>
            )}

            {/* JSON Viewer */}

            <Box
                component="pre"
                sx={{
                    backgroundColor: '#000',
                    color: '#fff',
                    padding: '10px',
                    borderRadius: '4px',
                    overflowX: 'auto',
                    fontFamily: 'monospace',
                    border: '1px solid #00aaff',
                    whiteSpace: 'pre-wrap',
                    marginLeft: '5%',
                    marginRight: '5%',
                    marginTop: '2%',
                    marginBottom: '2%'
                }}
            >
                <Typography
                    variant="h1"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column' // Ensure items stack vertically
                    }}
                >
                    {searchResult ? (
                        searchStatus === 200 ? (
                            <>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: 'white',
                                        backgroundColor: 'black',
                                        padding: '10px',
                                        borderRadius: '4px',
                                        width: '100%',
                                        marginTop: '0.5rem'
                                    }}
                                >
                                    {JSON.stringify(searchResult, null, 2)}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <span style={{ color: 'red', marginRight: '0.5rem' }}>Verification Failed</span>
                                <ErrorIcon sx={{ color: 'red', marginLeft: '0.5rem', fontSize: '3rem', marginTop: '0.3rem' }} />
                            </>
                        )
                    ) : (
                        <span>No data available</span>
                    )}
                </Typography>
            </Box>
        </Box>
    );
}

export default JSONViewer