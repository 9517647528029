import React, { useState, useEffect } from 'react'
import { Modal, Link, Button, Typography, IconButton, Box, CircularProgress, Stack, Popover } from '@mui/material';
import BarcodeSearchProvenance from './BarcodeSearchProvenance';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { useOktaAuth } from '@okta/okta-react';
import ControlledRadioButtonsGroup from './ControlledRadioButtonsGroup';



const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const WhiteButton = styled(Button)(({ theme, disabled }) => ({
    backgroundColor: disabled ? '#eeeeee' : theme.palette.common.white,
    color: disabled ? theme.palette.common.white : "black",
    '&:hover': {
        backgroundColor: disabled ? theme.palette.grey[300] : '#63b1e5',
    },
    borderRadius: 25,
    width: '100%',
    fontSize: 'small', // Set the font size here
    textTransform: 'capitalize',
    fontWeight: 'bolder',
    textAlign: 'center'
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}));


const SearchContentProvenance = ({ setSearchResult, setSearchStatus }) => {
    const [userInfo, setUserInfo] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [radioOptionValue, setRadioOptionValue] = React.useState('component_id_barcode');
    const [uploadedFile, setUploadedFile] = useState('');
    const [fileLoading, setFileLoading] = useState(false);
    const [searchText, setSearchText] = useState(''); 

    const { authState, oktaAuth } = useOktaAuth();
    useEffect(() => {
        console.log(authState)
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            setUserInfo(authState.idToken.claims);
            setAccessToken(authState.accessToken.accessToken);
        }
    }, [authState, oktaAuth]);

    const UploadFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSearchText('');
            setUploadedFile(file)
            UploadFileAPICall(file, accessToken);
            event.target.value = null
        }
    }

    const backend_base_url = process.env.REACT_APP_BACKEND_API_URL;

    const getURLMap = (radioValue) => {
        switch (radioValue) {
            case 'component_id_barcode': 
                return `${backend_base_url}/api/provenance/component-id-barcode-upload`;
            case 'IDevID_cert':
                return `${backend_base_url}/api/provenance/iDevID-cert-upload`;
            case 'HBOM': 
                return `${backend_base_url}/api/provenance/hbom-upload`;
            case 'SBOM': 
                return `${backend_base_url}/api/provenance/sbom-upload`;
            case 'CoRIM': 
                return `${backend_base_url}/api/provenance/coRIM-upload`;
        }
    };
    const UploadFileAPICall = async (file, accessToken) => {
        setFileLoading(true);

        const url = getURLMap(radioOptionValue);

        // console.log(file);
        // console.log(radioOptionValue);
        // console.log('Uploading to:', url);

        try {
            const params = { 'bearer_token': accessToken };
            // UPLOAD FILE LOGIC HERE 
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(
                url, 
                formData,
                {
                    params,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            const result = await response.data;
            setSearchResult(result)
            setSearchStatus(response.status)
            console.log(response.status)
            console.log('Upload result:', result);

            // Handle response here (e.g., success or error handling)
        } catch (error) {
            console.error('Error during file upload:', error);
        } finally {
            setFileLoading(false);
        }
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                height: { xs: '310px', sm: '350px', md: '480px', lg: '500px', xl: '600px' },
                width: '100%',
                overflow: 'hidden',
                alignItems: 'center',
            }}
        >
            <Box
                component="img"
                src="/assets/mi300_img.png"
                alt="mi300 image"
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: '-10',
                    minWidth: '100vw',
                    height: '100%',
                    objectFit: 'cover'
                }}
            />

            <Typography
                color="white"
                sx={{
                    position: 'absolute',
                    top: '5%',
                    left: '4%',
                    width: { xs: '80%', md: '50%' },
                    zIndex: 1,
                    fontWeight: 'bold',
                    fontSize: { xs: '2rem', md: '2rem', lg: '2rem', xl: "2rem" },
                    lineHeight: '1.5',
                    textAlign: 'left'
                }}
            >
                Device Lookup
            </Typography>

            <Typography
                color="white"
                sx={{
                    position: 'absolute',
                    top: { xs: '20%', md: '15%' },
                    left: '4%',
                    width: { sm: '80%', md: '70%', lg: '55%' },
                    zIndex: 1,
                    fontSize: { xs: '0rem', sm: '1rem', md: '1rem', lg: '1.1rem', xl: "1.2rem" },
                    lineHeight: '1.4',
                    marginTop: '0.5rem'
                }}
            >
                This tool is intended to provide product provenance information for any AMD product.
            </Typography>

            <Typography
                color="white"
                sx={{
                    position: 'absolute',
                    top: '22%',
                    left: '4%',
                    width: { sm: '80%', md: '70%', lg: '50%', xl: '50%' },
                    zIndex: 1,
                    fontSize: { xs: '0rem', md: '1rem', lg: '1.1rem', xl: "1.2rem" },
                    lineHeight: '1.4',
                    marginTop: '0.8rem'
                }}
            >
                Any unauthorized disclosure, use, dissemination, copying or the like of the information provided is prohibited.
            </Typography>

            <BarcodeSearchProvenance 
                onSearch={setSearchResult}
                setSearchText={setSearchText}
                searchText={searchText}
                onSearchStatus={setSearchStatus}
            />

            <Box
                sx={{
                    position: 'absolute',
                    left: {xs: '5%', sm: '4%'},
                    width: { xs: '90%', sm: '75%', md: '50%', lg: '40%', xl: '40%' },
                    textAlign: 'center',
                    top: { xs: '56%', sm: '53%', md: '50%', lg: '50%', xl: '45%' },
                }}
            >
                {/* First row */}
                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center', 
                        width: '100%', // Make the box span the full width
                        flexDirection: { xs: 'column', sm: 'row' },     
                        gap: { xs: 2, sm: 0 },
                    }}
                >
                    <Typography 
                        sx={{ 
                            fontSize: { xs: '0rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem', xl: '2rem' },  
                            fontWeight: 'bold', 
                            justifyContent: 'flex-start'
                        }}
                    >
                        File Upload Verification
                    </Typography>

                    <WhiteButton
                        variant="contained"
                        component="label"
                        startIcon={fileLoading ? <CircularProgress size={24} color="inherit" /> : <UploadFileIcon />}
                        sx={{
                            height: '3rem',
                            width: { xs: '100%', sm: '40%' },
                            textAlign: 'center',
                            '& .MuiCircularProgress-svg': {
                                color: 'black',
                            },
                        }}
                    >
                        {fileLoading ? (uploadedFile ? uploadedFile.name : 'Uploading...') : 'Upload Image/File'}
                        <VisuallyHiddenInput
                            type="file"
                            onChange={UploadFileChange}
                        />
                    </WhiteButton>
                </Box>


                {/* Second row */}
                <Box sx={{ display: 'flex' }}>
                <ControlledRadioButtonsGroup setOption={setRadioOptionValue} />
                </Box>
            </Box>
        </Box>
    )
}

export default SearchContentProvenance;