import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import SearchContentProvenance from '../componentsProvenance/SearchContentProvenance';
import {
    Box,
    Grid,
    Typography
  } from "@mui/material";
import { useOktaAuth } from '@okta/okta-react';
import JSONViewer from '../componentsProvenance/JSONViewer';


// Styling
const HEADER_HEIGHT = 90;

const BaseStyle = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowY: 'auto',
});

const ContentContainer = styled('div')(({ theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER_HEIGHT,
    paddingBottom: theme.spacing(0),
    position: 'relative',
}));



function SiliconProvenance() {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        console.log(authState)
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            setUserInfo(authState.idToken.claims);
            console.log(authState.idToken.claims.groups)
        }
    }, [authState, oktaAuth]);

    const [searchResult, setSearchResult] = useState('');
    const [searchStatus, setSearchStatus] = useState('');

    return (
        <div>
            <BaseStyle>
                {userInfo ? (
                    <ContentContainer>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={8}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: HEADER_HEIGHT,
                                        height: { xs: '310px', sm: '350px', md: '480px', lg: '500px', xl: '600px' },
                                        width: '100%',
                                        display: 'flex',
                                        overflow: 'hidden',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <SearchContentProvenance
                                        setSearchResult={setSearchResult}
                                        setSearchStatus={setSearchStatus}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        zIndex: 1,
                                        marginTop: {  xs: '320px', sm: '380px', md: '500px', lg: '520px', xl: '620px' },

                                    }}
                                >
                                    <JSONViewer searchResult={searchResult} searchStatus={searchStatus} />
                                </Box>
                            </Grid>
                        </Grid>
                        </ContentContainer>
                    )
                    :
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                        }}
                    >
                        <Typography variant="h3">Logging In........</Typography>
                    </Box>
                } 


            </BaseStyle>

        </div>
    )
}

export default SiliconProvenance;