import React from 'react';
import { Box, FormControl, RadioGroup, FormControlLabel, Radio, Select, MenuItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function ControlledRadioButtonsGroup({ setOption }) {
  const [value, setValue] = React.useState('component_id_barcode');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  console.log(isMobile)

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setOption(newValue);
  };

  return (
    <FormControl>
      {isMobile ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '5%',
            
          }}
        >
          <Select
            value={value}
            onChange={handleChange}
            sx={{
              color: 'black',
              fontSize: '0.8rem',
              backgroundColor: 'white',
              borderRadius: '30px',
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', 
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray', 
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: 'white', 
                  color: 'black', 
                  '& .MuiMenuItem-root': {
                    borderRadius: '15px',
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                      backgroundColor: 'gray', 
                    },
                  }
                },
              },
            }}
          >
            <MenuItem value="component_id_barcode">Component ID Barcode Image</MenuItem>
            <MenuItem value="IDevID_cert">IDevID Certificate</MenuItem>
            <MenuItem value="HBOM">HBOM File</MenuItem>
            <MenuItem value="SBOM">SBOM File</MenuItem>
            <MenuItem value="CoRIM">CoRIM</MenuItem>
          </Select>
        </Box>
      ) : (
        <Box sx={{
          display: 'flex', marginTop: '0.3rem', justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <FormControlLabel
              value="component_id_barcode"
              control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
              label="Component ID Barcode Image"
              sx={{ fontSize: '1.2rem', color: 'white' }}
            />
            <FormControlLabel
              value="IDevID_cert"
              control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
              label="IDevID Certificate"
              sx={{ fontSize: '1.2rem', color: 'white' }}
            />
            <FormControlLabel
              value="HBOM"
              control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
              label="HBOM File"
              sx={{ fontSize: '1.2rem', color: 'white' }}
            />
            <FormControlLabel
              value="SBOM"
              control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
              label="SBOM File"
              sx={{ fontSize: '1.2rem', color: 'white' }}
            />
            <FormControlLabel
              value="CoRIM"
              control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
              label="CoRIM"
              sx={{ fontSize: '1.2rem', color: 'white' }}
            />
          </RadioGroup>
        </Box>
      )}
    </FormControl>
  );
}
