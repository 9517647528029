import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import SearchContent from '../components/SearchContent';
import DownloadApp from '../components/DownloadApp';
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import CardDetails from '../components/CardDetails';
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';

// Styling
const HEADER_HEIGHT = 90;

const BaseStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  overflowY: 'auto',
});

const ContentContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER_HEIGHT,
  paddingBottom: theme.spacing(0),
  position: 'relative',
}));


function Main() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const [searchResult, setSearchResult] = useState(null);
  const [downloadResult, setDownloadResult] = useState(null);
  const [ip, setIP] = useState("");
  const backend_base_url = process.env.REACT_APP_BACKEND_API_URL;

  const [additionalDetails, setAdditionalDetails] = useState([]);

  const getIPAddress = async (token) => {
    try {
      const params = { 'bearer_token': token };
      const res = await axios.get(`${backend_base_url}/api/STSProDataQuery/get-client-ip`, { params });
      setIP(res.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      setUserInfo(authState.idToken.claims);
      getIPAddress(authState.accessToken.accessToken);
    }
  }, [authState, oktaAuth]);

  // useEffect(() => {
  //   getIPAddress();
  // }, [getIPAddress]);

  return (
    <div>
      <BaseStyle>
        {userInfo ? (
        <ContentContainer>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={8}>
              <Box
                sx={{
                  position: 'absolute',
                  top: HEADER_HEIGHT,
                  height: { xs: '300px', sm: '350px', md: '400px', lg: '440px', xl: '500px' },
                  width: '100%',
                  display: 'flex',
                  overflow: 'hidden',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SearchContent
                  setSearchResult={setSearchResult}
                  setDownloadResult={setDownloadResult}
                  ip={ip}
                  authState={authState}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 1,
                  marginTop: { xs: '300px', sm: '350px', md: '400px', lg: '440px', xl: '500px' },

                }}
              >
                <CardDetails searchResult={searchResult} downloadResult={downloadResult} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 1,
                  marginTop: '100px'

                }}
              >
                <DownloadApp />
              </Box>
            </Grid>
          </Grid>
        </ContentContainer>)
          : 
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', 
          }}
        >
          <Typography variant="h3">Logging In........</Typography>
        </Box>
        }


      </BaseStyle>

    </div>
  );
}

export default Main;
