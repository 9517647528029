import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  ClickAwayListener,
} from "@mui/material";

const SearchBox = styled('div')(({ theme, expanded }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 6,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(0.5, 1),
  transition: theme.transitions.create(['width'], {
    duration: theme.transitions.duration.shorter,
  }),
  width: expanded ? '75ch' : '55ch',
  [theme.breakpoints.down('lg')]: {
    width: expanded ? '45ch' : '28ch',
  },
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
  color: "black", // Ensure text color contrasts with the background
}));

const SearchBar = () => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleTextChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ display: 'flex', alignItems: 'center'}}>
        <SearchBox expanded={open}>
          <SearchInput
            placeholder="Search services and docs ..."
            value={searchText}
            onChange={handleTextChange}
            onClick={handleClick}
          />
          <IconButton type="submit" aria-label="search" onClick={handleClick}>
            <SearchIcon />
          </IconButton>
        </SearchBox>
      </Box>
    </ClickAwayListener>
  );
};

export default SearchBar;
