// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Gotham', 'Arial', 'sans-serif'].join(','),
    fontSize: 12,
    h1: {
      fontFamily: ['Gotham', 'Arial', 'sans-serif'].join(','),
      fontSize: 40,
    },
    h2: {
      fontFamily: ['Gotham', 'Arial', 'sans-serif'].join(','),
      fontSize: 32,
    },
    h3: {
      fontFamily: ['Gotham', 'Arial', 'sans-serif'].join(','),
      fontSize: 24,
    },
    h4: {
      fontFamily: ['Gotham', 'Arial', 'sans-serif'].join(','),
      fontSize: 20,
    },
    h5: {
      fontFamily: ['Gotham', 'Arial', 'sans-serif'].join(','),
      fontSize: 16,
    },
    h6: {
      fontFamily: ['Gotham', 'Arial', 'sans-serif'].join(','),
      fontSize: 14,
    },
  },
  palette: {
    background: {
      default: '#000000',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

export default theme;
